import React from 'react'
import { Formik } from 'formik'
import { navigateTo } from 'gatsby'
import * as Yup from 'yup'

import { Error, Success, Loading } from './Alert'
import TaskComponent from './TaskComponent'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .lowercase()
    .matches(/^[a-z]([-']?[a-z]+)*( [a-z]([-']?[a-z]+)*)+$/, {
      message: 'il nous manque votre nom ou votre prénom',
    })
    .required('requis'),
  email: Yup.string()
    .email('doit être valide')
    .required('requise'),
  company: Yup.string().required('requis'),
  phone: Yup.string()
    .matches(/^[0-9]{10}/, {
      message: 'doit être valide',
    })
    .required('requis'),
  message: Yup.string()
    .min(10, 'doit comporter plus de 10 caractères')
    .required('requis'),
})

function CheckListItem({ text, error }) {
  return (
    <li style={{ color: error ? 'red' : 'green' }}>
      <span className={error ? 'fa fa-close' : 'fa fa-check'} /> {text}{' '}
      {error && `(${error})`}
    </li>
  )
}

function CheckList({ errors, touched }) {
  const error = key => touched[key] && errors[key]
  return (
    <div className="align-left">
      <strong>Nous avons besoin</strong>
      <ul>
        <CheckListItem text="de votre nom et prénom" error={error('name')} />
        <CheckListItem text="de votre adresse mail" error={error('email')} />
        <CheckListItem
          text="du nom de votre société"
          error={error('company')}
        />
        <CheckListItem
          text="de votre n° mobile professionnel"
          error={error('phone')}
        />
        <CheckListItem text="de votre message" error={error('message')} />
      </ul>
    </div>
  )
}

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

function sendMessage(values, { setSubmitting, setErrors }) {
  return fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encode({ 'form-name': 'contact', ...values }),
  })
}
function InnerForm({
  handleSubmit,
  handleChange,
  handleBlur,
  errors,
  touched,
  values,
  isSubmitting,
}) {
  return (
    <form
      onSubmit={handleSubmit}
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <CheckList errors={errors} touched={touched} />
      <div className="grid-wrapper" style={{ width: '100%' }}>
        <div className="col-6">
          <input
            type="text"
            name="name"
            placeholder="Nom Prénom"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
          />

          <input
            type="text"
            name="email"
            placeholder="Adresse Mail"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
        </div>
        <div className="col-6">
          <input
            type="text"
            name="company"
            placeholder="Société"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.company}
          />

          <input
            type="text"
            name="phone"
            maxLength={10}
            placeholder="Téléphone"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phone}
          />
        </div>
        <div className="col-12">
          <textarea
            name="message"
            placeholder="Message.."
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.message}
          />
        </div>
      </div>

      <button disabled={isSubmitting} type="submit">
        Envoyer
      </button>
    </form>
  )
}
function Contact() {
  return (
    <TaskComponent
      task={sendMessage}
      render={{
        notAsked: onSubmit => (
          <Formik
            initialValues={{
              name: '',
              company: '',
              email: '',
              phone: '',
              message: '',
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            render={props => <InnerForm {...props} />}
          />
        ),
        loading: () => <Loading />,
        error: error => <Error />,
        success: data => <Success />,
      }}
      onCompleted={() => setTimeout(() => navigateTo('/'), 1500)}
    />
  )
}

export default Contact
