import React from 'react'

import loader from '../assets/images/loader.svg'

function Error() {
  return (
    <Alert backgroundColor="#d9534f">
      <h1>Une erreur est survenue !</h1>
      <p>
        Veuillez nous contacter par mail à cette adresse:{' '}
        <a href="mailto:contact@imobat.fr">contact@imobat.fr</a>
      </p>
      <button onClick={() => window.location.reload(true)}>Recommencer</button>
    </Alert>
  )
}

function Loading() {
  return (
    <Alert backgroundColor="#111111">
      <img src={loader} alt="Envoi en cours..." />
    </Alert>
  )
}

function Success() {
  return (
    <Alert backgroundColor="#5cb85c">
      <h1 style={{ color: '#fff' }}>
        <span className="fa fa-check" /> Nous avons bien reçu votre message
      </h1>
    </Alert>
  )
}

function Alert({ backgroundColor, children }) {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: '100%',
        minHeight: '100vh',
        background: backgroundColor,
        zIndex: 1050,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default Alert
export { Error, Loading, Success }
