import React from 'react'
import PropTypes from 'prop-types'

const NOTASKED = 'NOTASKED'
const LOADING = 'LOADING'
const SUCCESS = 'SUCCESS'
const ERROR = 'ERROR'

const cata = (type, data = null) => o => o[type](data)

const Data = {
  notAsked: () => ({ type: NOTASKED, cata: cata(NOTASKED) }),
  loading: () => ({ type: LOADING, cata: cata(LOADING) }),
  error: error => ({ type: ERROR, error, cata: cata(ERROR, error) }),
  success: data => ({ type: SUCCESS, data, cata: cata(SUCCESS, data) }),
}

class TaskComponent extends React.Component {
  state = Data.notAsked()

  static defaultProps = {
    onCompleted: undefined,
  }

  static propTypes = {
    task: PropTypes.func.isRequired,
    render: PropTypes.shape({
      notAsked: PropTypes.func,
      loading: PropTypes.func,
      error: PropTypes.func,
      success: PropTypes.func,
    }).isRequired,
    onCompleted: PropTypes.func,
  }

  showSuccess = data => {
    this.setState(
      Data.success(data),
      () => this.props.onCompleted && this.props.onCompleted(data)
    )
  }

  showError = error => {
    this.setState(Data.error(error), () => console.error(error))
  }

  loadData = (...args) => {
    this.setState(Data.loading())
    const fetchData = this.props.task(...args)
    fetchData.then(this.showSuccess, this.showError).catch(this.showError)
  }

  render() {
    const { render } = this.props
    return this.state.cata({
      NOTASKED: () => render.notAsked(this.loadData),
      LOADING: render.loading,
      ERROR: render.error,
      SUCCESS: render.success,
    })
  }
}

export default TaskComponent
