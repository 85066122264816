import React from 'react'
import { Map, TileLayer, Marker } from 'react-leaflet'

const position = [48.157754, -1.82901]

function LeafletMap() {
  if (typeof window !== 'undefined') {
    return (
      <Map center={position} zoom={15} scrollWheelZoom={false}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={position} />
      </Map>
    )
  }
  return null
}

export default LeafletMap
