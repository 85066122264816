import React from 'react'
import { graphql } from 'gatsby'

import Header from '../components/Header'
import LeafletMap from '../components/LeafletMap'
import Contact from '../components/Contact'
import Layout from '../layouts'

function ContactPage({ location, data }) {
  const { header, form } = data.markdownRemark.frontmatter
  return (
    <Layout location={location}>
      <div className="animPage">
        <Header title={<span>{header.title}</span>} />
        <LeafletMap />
        <section className="main style1 special">
          <div className="container">
            <header className="major ">
              <h2>{form.title}</h2>
            </header>
            <Contact />
          </div>
        </section>
      </div>  
    </Layout>
  )
}

export default ContactPage

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        header {
          title
        }
        form {
          title
        }
      }
    }
  }
`
